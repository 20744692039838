import React, { useState, useEffect } from 'react';
import { CheckCircle, XCircle, RefreshCw } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { Toaster } from "@/components/ui/toaster";

const servers = [
  {
    "Name": "Vanilla Minecraft",
    "ip": "mc.donchong.top",
    "port": 25565,
    "type": "Minecraft",
    "icon": "vanilla",
    "restartable": false,
  },
  {
    "Name": "GTNH",
    "ip": "GTNH.donchong.top",
    "port": 25566,
    "type": "Minecraft",
    "icon": "vanilla",
    "restartable": false,
  },
  {
    "Name": "TerraFirmaGreg",
    "ip": "tfg.donchong.top",
    "port": 25567,
    "type": "Minecraft",
    "icon": "vanilla",
    "restartable": true,
  },
  {
    "Name": "Personal Website",
    "ip": "donchong.top",
    "port": 80,
    "type": "Website",
    "icon": "vanilla",
    "restartable": false,
  },
  {
    "Name": "Bill",
    "ip": "bill.donchong.top",
    "port": 80,
    "type": "Website",
    "icon": "vanilla",
    "restartable": false,
  },
];


const checkWebpageStatus = ({ ip, port }) => {
  console.log("Checking status of " + ip + ":" + port);
  return new Promise((resolve) => {
    const img = new Image();
    const timeout = setTimeout(() => {
      resolve('offline');
    }, 5000);

    img.onload = () => {
      clearTimeout(timeout);
      resolve('online');
    };

    img.onerror = () => {
      clearTimeout(timeout);
      resolve('offline');
    };

    img.src = `http://${ip}:${port}/favicon.ico?${new Date().getTime()}`;
  });
};

async function checkMinecraftServerStatus(server) {
  const serverIP = server.ip;
  const apiUrl = `https://api.mcsrvstat.us/2/${serverIP}`;
  
  try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      
      if (data.online) {
        // console.log(`Server is online with ${data.players.online} players`);
        return 'online';
      } else {
          console.log('Server is offline');
          return 'offline';
      }
  } catch (error) {
      console.error('Error fetching server status:', error);
  }
}

const pingServer = (server) => {
  return new Promise((resolve) => {
      if(server.type === "Website"){
        resolve(checkWebpageStatus(server))
      }else{
        resolve(checkMinecraftServerStatus(server))
      }
  });
};

const ServerStatus = ({ server }) => {
  const [status, setStatus] = useState('checking');
  const { toast } = useToast();

  useEffect(() => {
    const checkStatus = async () => {
      const result = await pingServer(server);
      setStatus(result);
    };
    checkStatus();
    const interval = setInterval(checkStatus, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [server]);

  const [isRestarting, setIsRestarting] = useState(false);

  const handleRestart = async () => {
    // setIsRestarting(true);
    // setIsRestarting(false);

    if(server.Name !== "TerraFirmaGreg"){return;}

    let url = `https://status.donchong.top/startTFG`;

    const fetchPromise = await fetch(url);
    const response = await fetchPromise.json();

    if(response === 'error code: 521'){
      toast({
        title: "Restart Failed",
        description: "Origin server is not running",
        variant: "destructive",
        duration: 5000,
      })
      return;
    }

    if(response.success === 'true'){
      toast({
        title: "Restart Initiated",
        description: response.message,
        duration: 5000,
      });
      return;
    }
    
    if (response.success === 'false'){
      toast({
        title: "Restart Failed",
        description: response.message,
        variant: "destructive",
        duration: 5000,
      });
      return;
    }
  };

  return (
    <div className="flex items-center justify-between p-4 border-b">
      <div className="flex-grow">
        <h3 className="text-lg font-semibold">{server.Name}</h3>
        <p className="text-sm text-gray-500">{server.ip}</p>
      </div>
      <div className="flex items-center space-x-4">
        {status === 'online' ? (
          <CheckCircle className="text-green-500" size={24} />
        ) : status === 'offline' || status === 'timeout' ? (
          <XCircle className="text-red-500" size={24} />
        ) : (
          <div className="w-6 h-6 border-2 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
        )}
        <Button
          onClick={handleRestart}
          disabled={status === 'online' || isRestarting || server.restartable === false}
          className={`${status === 'online' ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white'} px-4 py-2 rounded`}
        >
          ▶
        </Button>
      </div>
    </div>
  );
};

const PingPanel = () => {
  return (
    <div className="max-w-md mx-auto mt-10 bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
      <div className="p-8">
        <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mb-4">Server Status</div>
        {servers.map((server, index) => (
          <ServerStatus key={index} server={server} />
        ))}
      </div>
      <Toaster />
    </div>
  );
};

export default PingPanel;