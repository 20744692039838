import './App.css';
import PingPanel from './components/PingPanel';
import React from 'react';

function App() {
  return (
    <div>
      <PingPanel />
    </div>
  );
}

export default App;
